import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import drupal_7 from "../../../assets/images/blog/drupal_7/drupal_7.jpg"
import drupal_7_1 from "../../../assets/images/blog/drupal_7/drupal_7_1.jpg"
import drupal_7_2 from "../../../assets/images/blog/drupal_7/drupal_7_2.jpg"
import drupal_7_3 from "../../../assets/images/blog/drupal_7/drupal_7_3.jpg"
import pdf from "../../../assets/images/blog/drupal_7/iconww_Drupal7_to_Drupal8_FREE_CHECKLIST.pdf"

export default () => 
<div>
    <SEO title={"Drupal 7 End of Life: Manage Your Upgrade to Drupal 8 and 9 [+ FREE Site Migration Checklist]"} 
    description="The time has come: Drupal 7 end of life is almost here. So, are you still wondering why and how you should upgrade your website? Discover it on our blog!"
    canonical={'https://icon-worldwide.com/blog/drupal-7-end-of-life'}
    image={'https://icon-worldwide.com/social_images/drupal_7-end-of-life.jpg'} />

    <NewsHeader/>

    <div id="single-news">
            <h1><span>Drupal 7 End of Life:</span> Manage Your Upgrade to Drupal 8 and 9 [+ FREE Site Migration Checklist]</h1>
            <div id="title-underline"></div>
            <img src={drupal_7} alt="Drupal 7 End of Life" title="ICON, Drupal 7 End of Life" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
        <div id="single-news-texts">
            <p><strong><a href="https://drupalize.me/guide/introduction-drupal" target="_blank" rel="noopener noreferrer">Drupal</a> is one of the most widely used content management systems worldwide.</strong></p>
            <p>Thanks to core attributes such as content authoring, solid performance, outstanding security and versatility, Drupal can be considered the <strong>
                perfect solution to create dynamic web experiences and integrated digital frameworks</strong>: many of the most well-known websites run on Drupal, 
                including: <a href="https://www.bbc.com/" target="_blank" rel="noopener noreferrer">BBC</a>, <a href="https://www.nbc.com/" target="_blank" rel="noopener noreferrer">NBC</a>, <a href="https://www.amnesty.org/en/" target="_blank" rel="noopener noreferrer">Amnesty International</a> and the <a href="https://www.ox.ac.uk/ " target="_blank" rel="noopener noreferrer">University of Oxford</a>!
            </p>
            <p>The main strength of <a href="https://www.designrush.com/agency/drupal" target="_blank" rel="noopener noreferrer">Drupal</a>, in fact, is its ability to periodically increase its potential: Drupal 7 was first released in January 2011, but soon, <strong>in November 2022,
                 after over a decade, will reach end of life.  However,</strong> altough the official community support for it will end – along with the support usually provided by the Drupal 
                 Association on <a href="https://www.drupal.org/ " target="_blank" rel="noopener noreferrer">Drupal.org</a> – a Drupal 7 Vendor Extended Support program will be in place until November 2025, allowing for Drupal 7 users to sign up for support with select vendors.
            </p>
            <p>Probably, you are here because you already knew about it and your website is running on Drupal 7: so, the time has come to <strong><Link to="/services/acquia-and-drupal-development">start planning your transition to Drupal 8</Link></strong> and then – soon – to Drupal 9.</p>
            <h2>What does it mean that Drupal 7 has reached end of life?</h2>
            <img src={drupal_7_1} alt="Drupal 7 has reached end of life" title="ICON, Drupal 7 has reached end of life" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p><strong>When a piece of software reaches its end of life date, it will no longer receive bug fixes or <Link to="/blog/security-checklist-for-developers">security</Link> updates from the maintainers</strong>: so, 
                this means that automated testing services, updates, and security fixes for Drupal 7 are going to be withdrawn.
            </p>
            <p> Overall, this means your website and associated plugins will no longer be secure.  In today’s current 
                environment of incessant DDOS and other attacks, your website will almost certainly be attacked and potentially damaged.
            </p>
            <p>The main reason for ending support is that maintainers want to <strong>push the adoption of the most modern platform’s version, which can truly fortify the user’s website in terms of technology and performances</strong>.</p>
            <p>Drupal 7, in fact, was a cutting-edge step forward when it was released, but Drupal 8 represents a complete rework of the websites’ architecture and offers new powerful features, including a totally renovated release cycle.  Drupal 9 is an even further evolution.</p>
            <p>Web migrations may sound scary, we know, but they are worth it, and this time there are several reasons. To sum them up:</p>
            <ul>
                <li><strong>As we’ve already said, Drupal 7 will no longer be supported by the community at large;</strong></li>
                <li><strong>The Drupal Security Team will no more provide <a href="https://www.drupal.org/security" target="_blank" rel="noopener noreferrer">security advisories</a>, so the reports about Drupal 7 vulnerabilities might become public, creating zero-day exploits;</strong></li>
                <li><strong>After November 2022, using Drupal 7 may be flagged as insecure in third-party scans and you should never use an unsupported software for your website.</strong></li>
            </ul>
            <h2>The main benefits of upgrading your site </h2>  
            <img src={drupal_7_2} alt="The main benefits of upgrading your site" title="ICON, the main benefits of upgrading your site" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>You are likely also aware that Drupal 9 has been released – and you might even consider an upgrade directly from Drupal 7 to Drupal 9.</p>
            <p>However, we recommend following <strong>the best practice of upgrading to Drupal 8 first, and then to Drupal 9</strong>. The effort to upgrade from Drupal 7 to 8 is indeed significant, however Drupal 8 is a proven stable version in the market for some time.</p> 
            <p>Drupal 9 is very new and not all modules have been fully tested and confirmed in real use. Therefore it makes sense to begin with an upgrade to the mature Drupal 8 first.  The eventual upgrade to Drupal 9 will then be far easier. </p>
            <p>In doing so, you’ll discover that the benefits of a Drupal 8 migration are bigger than ever, <strong>with Drupal 8 being a modern, stable platform, able to manage extensive websites with complex functionalities</strong>.</p>
            <p><strong>Compared to Drupal 7, Drupal 8 offers</strong>:</p>
            <ul>
                <li><strong>A new Theme Engine and Text Editor</strong>: this means easier syntax, as well as versatile <a href="https://www.previousnext.com.au/blog/testing-twig-templates-and-custom-javascript-jest" target="_blank" rel="noopener noreferrer">theming</a> and editing;</li>
                <li><strong>Flexible Field Types</strong>: new <a href="https://drupalize.me/topic/fields" target="_blank" rel="noopener noreferrer">field types</a> for custom use cases;</li>
                <li><strong>Quicker Editing</strong>: the possibility to modify any content directly from the website’s frontend;</li>
                <li><strong>Core Multilingual</strong>: new interesting modules like <a href="https://drupalize.me/topic/multilingual-sites" target="_blank" rel="noopener noreferrer">language</a>, content translation, and interface translation;</li>
                <li><strong>Views in Core</strong>: this makes it easier for developers to get access and customize <a href="https://drupalize.me/topic/display-content-views" target="_blank" rel="noopener noreferrer">views</a>;</li>
                <li><strong>Built-in Web Services</strong>: cutting-edge tools to create app-like experiences directly from your website;</li>
                <li><strong>Built-in Configuration Management</strong>: to simply moving configuration elements to the web server and track those changes through version control;</li>
                <li><strong>Faster Website Loading</strong>: an easier loading speed increase by caching entities, compressing information, reducing web server load, loading previously viewed content from the cache, and holding JavaScript code, so as to improve your site’s user experience and SEO performance;</li>
                <li><strong>Great support for Website Accessibility</strong>: possibility to use HTML5 natively, by leveraging improved features to make the website be accessible across devices;</li>
                <li><strong>Time-saving PHP</strong>: creating automated processes that make static websites dynamic and <a href="https://www.nngroup.com/articles/responsive-web-design-definition/#:~:text=Responsive%20web%20design%20(RWD)%20is,being%20used%20to%20view%20it.&text=In%20responsive%20design%2C%20page%20elements,the%20viewport%20grows%20or%20shrinks."  target="_blank" rel="noopener noreferrer">responsive</a>, 
                     easily rendering and programming HTML;
                </li>
                <li><strong>Security innovations</strong>: new security modules have been created to fortify the login process, block IP addresses, send alerts in case of potential attacks, integrate CAPTCHAs to avoid spammers, and select strong passwords;</li>
                <li><strong>Fortified SEO performance</strong>: finally, several new SEO-friendly modules will help you create descriptive URL paths, implement metadata, redirect traffic from old URLs, <a href="https://support.google.com/analytics/answer/1008015?hl=en" target="_blank" rel="noopener noreferrer">configure Google Analytics</a>, and analyze your 
                     current <Link to="/services/seo">SEO performance</Link>.
                </li>
            </ul>
            <h2>Not enough time? Try our migration checklist!</h2>
            <img src={drupal_7_3} alt="Try our migration checklist" title="ICON, try our migration checklist" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>A website <a href="https://searchcontentmanagement.techtarget.com/definition/content-management-system-CMS" target="_blank" rel="noopener noreferrer">Content Management System</a> reaching its end of life is not something anyone wants to learn about: indeed, <strong>you 
                surely have a lot of things to do and may not have the time and resources to cope with such a web migration</strong>.
            </p> 
            <p>But <strong>with changes come new awesome opportunities</strong>, above all in the digital space: it can sound scary, but is also necessary.</p>
            <p>So, <strong>why not simplify this process by downloading our FREE SITE MIGRATION CHECKLIST below</strong>? Wait no more, start your website revolution right now!</p> 
        </div>


        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <p><strong>Would you need some help with your website transition from Drupal 7 to Drupal 8? If so, download our FREE SITE MIGRATION CHECKLIST below!</strong></p>
        <h4><a href={pdf} target="_blank" rel="noopener noreferrer" className="cta cta-long">GET YOUR FREE CHECKLIST</a></h4>

            <NewsFooter previous="ecommerce-trends-2020" next="inhouse-vs-nearshoresoftware-development-team"/>
        
    </div>

    <Footer noScrollbar="true"/>
</div>